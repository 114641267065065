import ProtonWebSDK, { ProtonWebLink, LinkSession, TransactResult, Link } from "@proton/web-sdk";
import { Api, JsonRpc, RpcError, JsSignatureProvider, Key } from "@proton/js";
import $ from "jquery";

import { defaultPK } from "constants";
import { isObjectEmpty } from "functions"

const rpc = new JsonRpc(["https://proton.eosusa.io", "https://api-proton.saltant.io", "https://proton.pink.gg"]);

export async function isKycVerified(username) {
    if (!username) return Promise.reject("username: undefined");
    
    const result = await rpc.get_table_rows({
        json: true,
        code: "eosio.proton",
        scope: "eosio.proton",
        table: "usersinfo",
        upper_bound: username,
        lower_bound: username
    });

    return (result.rows[0]?.verified || result.rows[0]?.kyc?.length > 0) ? true : false;
};

export async function connect() {
    try {
        return ProtonWebSDK({
            linkOptions: {
                endpoints: ["https://proton.eosusa.io", "https://api-proton.saltant.io", "https://proton.pink.gg"],
                chainId: "384da888112027f0321850a169f737c33e53b388aad48b5adace4bab97f437e0",
                restoreSession: false
            },
            transportOptions: {
                requestAccount: "storexcore",
                requestStatus: true
            },
            selectorOptions: {
                appName: "Storex",
                appLogo: "/assets/images/webauth_logo_black.svg",
                customStyleOptions: {
                    modalBackgroundColor: "#ffffff",
                    logoBackgroundColor: "#ffffff",
                    isLogoRound: true,
                    optionBackgroundColor: "#ffffff",
                    optionFontColor: "#666666",
                    primaryFontColor: "#666666",
                    secondaryFontColor: "#666666"
                },
                //enabledWalletTypes: ["proton", "webauth"]
            },
        });
    } catch {
        $(".proton-link").removeClass("proton-link-active");

        return false;
    };
};

export function restore() {
    return ProtonWebSDK({
        linkOptions: {
            endpoints: ["https://proton.eosusa.io", "https://api-proton.saltant.io", "https://proton.pink.gg"],
            chainId: "384da888112027f0321850a169f737c33e53b388aad48b5adace4bab97f437e0",
            restoreSession: true
        },
        transportOptions: {
            requestAccount: "storexcore",
            requestStatus: false
        },
        selectorOptions: {
            appName: "Storex",
            appLogo: "/assets/images/webauth_logo_black.svg",
        }
    });
};

export function verify(session, publicToken) {
    if (!session) return Promise.reject("session: undefined");
    if (!publicToken) return Promise.reject("publicToken: undefined");

    return session.transact({
            actions: [{
                account: "storexcore",
                name: "acc.verify",
                data: {
                    user: session.auth.actor,
                    verify_token: publicToken
                },
                authorization: [session.auth]
            }]
        }, {
            broadcast: false
        });
};

export async function generateAuth(state) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        let res = await api.transact({
            actions: [{
                account: "proton.wrap",
                name: "generateauth",
                data: {
                    protonAccount: state.session.auth.actor,
                    time: "2024-01-01T00:00:00.000"
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: false,
            blocksBehind: 3,
            expireSeconds: 30
        });

        res.transaction = await api.deserializeTransactionWithActions(res.serializedTransaction);
        res.signer = state.session.auth;

        return res;
    } else {
        if (!state) return Promise.reject("state: undefined");

        let res = await state.session.transact({
            actions: [{
                account: "proton.wrap",
                name: "generateauth",
                data: {
                    protonAccount: state.session.auth.actor,
                    time: "2024-01-01T00:00:00.000"
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: false
        });

        res.transaction = res.resolvedTransaction;

        return res;
    };
};

export function metalLogin(email, signatures, signer, transaction, verificationCode) {
    if (!email) return Promise.reject("email: Undefined");
    if (!signatures) return Promise.reject("signatures: Undefined");
    if (!signer) return Promise.reject("signer: Undefined");
    if (!transaction) return Promise.reject("transaction: Undefined");
    
    return $.ajax({
        type: "POST",
        url: "https://identity.api.prod.metalx.com/v3/users/login",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify({
            email: email,
            verificationCode: verificationCode,
            signer: signer,
            signatures: signatures,
            transaction: transaction
        }),
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");
        }
    });
};

export function metalKYCStatus(jwt) {
    if (!jwt) return Promise.reject("jwt: Undefined");
    
    return $.ajax({
        type: "GET",
        url: "https://identity.api.prod.metalx.com/v2/kyc/status",
        headers: {
            "Authorization": "Bearer " + jwt
        },
        contentType: "application/json",
        dataType: "json",
        data: [],
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");
        }
    });
};

export function metalKYC(jwt, address1, address2, city, state, postcode, country) {
    if (!jwt) return Promise.reject("jwt: Undefined");
    if (!address1) return Promise.reject("address1: Undefined");
    if (!city) return Promise.reject("city: Undefined");
    if (!state) return Promise.reject("state: Undefined");
    if (!postcode) return Promise.reject("postcode: Undefined");
    if (!country) return Promise.reject("country: Undefined");
    
    return $.ajax({
        type: "POST",
        url: "https://identity.api.prod.metalx.com/v2/kyc/start",
        headers: {
            "Authorization": "Bearer " + jwt
        },
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify({
            address: {
                city: city,
                country: country,
                postalCode: postcode,
                street1: address1,
                street2: address2,
                subdivision: state?.toUpperCase()?.slice(0, 3)
            },
            hideLegalName: true
        }),
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");
        }
    });
};

export function sendTokens(state, currencyConst, selectedChain, withdrawAddress, withdrawAmount, memo) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (!currencyConst) return Promise.reject("currencyConst: Undefined");
        if (!selectedChain) return Promise.reject("selectedChain: Undefined");
        if (!withdrawAddress) return Promise.reject("withdrawAddress: Undefined");
        if (!withdrawAmount) return Promise.reject("withdrawAmount: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: [{
                account: currencyConst.contract,
                name: "transfer",
                data: {
                    from: state.session.auth.actor,
                    to: withdrawAddress,
                    quantity: parseFloat(withdrawAmount).toFixed(currencyConst.precision) + " " + currencyConst.ticker,
                    memo: memo || ""
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 30
        });
    } else {
        if (!state || !currencyConst || !selectedChain || !withdrawAddress || !withdrawAmount) return Promise.reject("missing_parameters");

        return state.session.transact({
            actions: [{
                account: currencyConst.contract,
                name: "transfer",
                data: {
                    from: state.session.auth.actor,
                    to: withdrawAddress,
                    quantity: parseFloat(withdrawAmount).toFixed(currencyConst.precision) + " " + currencyConst.ticker,
                    memo: memo || ""
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true
        });
    };
};

export async function signWithdraw(state, currencyConst, selectedChain, withdrawAddress, withdrawMemo, withdrawAmount, withdrawFee) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (!currencyConst) return Promise.reject("currencyConst: Undefined");
        if (!selectedChain) return Promise.reject("selectedChain: Undefined");
        if (!withdrawAddress) return Promise.reject("withdrawAddress: Undefined");
        if (!withdrawAmount) return Promise.reject("withdrawAmount: Undefined");
        if (!withdrawFee) return Promise.reject("withdrawFee: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        let res = await api.transact({
            actions: [{
                account: currencyConst.contract,
                name: "transfer",
                data: {
                    from: state.session.auth.actor,
                    to: "proton.wrap",
                    quantity: parseFloat(parseFloat(withdrawAmount) + parseFloat(withdrawFee)).toFixed(currencyConst.precision) + " " + currencyConst.ticker,
                    memo: currencyConst.name + ":" + currencyConst.networks[selectedChain] + ":" + withdrawAddress + (withdrawMemo ? "?memo=" + withdrawMemo : "") + ":" + parseFloat(withdrawFee)
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: false,
            useLastIrreversible: true,
            expireSeconds: 30
        });

        res.transaction = await api.deserializeTransactionWithActions(res.serializedTransaction);
        res.signer = state.session.auth;

        return res;
    } else {
        if (!state) return Promise.reject("state: Undefined");
        if (!currencyConst) return Promise.reject("currencyConst: Undefined");
        if (!selectedChain) return Promise.reject("selectedChain: Undefined");
        if (!withdrawAddress) return Promise.reject("withdrawAddress: Undefined");
        if (!withdrawAmount) return Promise.reject("withdrawAmount: Undefined");
        if (!withdrawFee) return Promise.reject("withdrawFee: Undefined");

        let res = await state.session.transact({
            actions: [{
                account: currencyConst.contract,
                name: "transfer",
                data: {
                    from: state.session.auth.actor,
                    to: "proton.wrap",
                    quantity: parseFloat(parseFloat(withdrawAmount) + parseFloat(withdrawFee)).toFixed(currencyConst.precision) + " " + currencyConst.ticker,
                    memo: currencyConst.name + ":" + currencyConst.networks[selectedChain] + ":" + withdrawAddress + (withdrawMemo ? "?memo=" + withdrawMemo : "") + ":" + parseFloat(withdrawFee)
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: false
        });

        res.transaction = res.resolvedTransaction;

        return res;
    };
};

export function sendTrade(state, inputCurrency, inputAmount, minimumReceived, route) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (!inputCurrency) return Promise.reject("inputCurrency: Undefined");
        if (!inputAmount) return Promise.reject("inputAmount: Undefined");
        if (!minimumReceived) return Promise.reject("minimumReceived: Undefined");
        if (!route) return Promise.reject("route: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: [{
                account: inputCurrency.contract,
                name: "transfer",
                data: {
                    from: state.session.auth.actor,
                    to: "router.strx",
                    quantity: parseFloat(inputAmount).toFixed(inputCurrency.precision) + " " + inputCurrency.ticker,
                    memo: "swap@" + route + "," + minimumReceived
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 30
        });
    } else {
        if (!state) return Promise.reject("state: Undefined");
        if (!inputCurrency) return Promise.reject("inputCurrency: Undefined");
        if (!inputAmount) return Promise.reject("inputAmount: Undefined");
        if (!minimumReceived) return Promise.reject("minimumReceived: Undefined");
        if (!route) return Promise.reject("route: Undefined");

        return state.session.transact({
            actions: [{
                account: inputCurrency.contract,
                name: "transfer",
                data: {
                    from: state.session.auth.actor,
                    to: "router.strx",
                    quantity: parseFloat(inputAmount).toFixed(inputCurrency.precision) + " " + inputCurrency.ticker,
                    memo: "swap@" + route + "," + minimumReceived
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true
        });
    };
};

export function sendWithdraw(tx, quoteId) {
    return $.ajax({
        type: "POST",
        url: "https://www.api.bloks.io/proton-wrap-public2/withdraw",
        processData: false,
        contentType: "application/json",
        data: JSON.stringify({
            signatures: tx.signatures.map((signature) => String(signature)),
            //transaction: JSON.parse(JSON.stringify(tx.transaction)),
            transaction: tx.transaction,
            signer: {
                actor: String(tx.signer.actor),
                permission: String(tx.signer.permission)
            },
            quoteId: quoteId
        }),
        error: function(err) {
            console.log(err);
        }
    });
};

export function calculateFee(coin, network) {
    if (!coin) return Promise.reject("coin: Undefined");
    if (!network) return Promise.reject("network: Undefined");

    return $.ajax({
        type: "POST",
        url: "https://api.protonchain.com/v1/swaps/calculate-estimated-fee",
        processData: false,
        contentType: "application/json",
        data: JSON.stringify({
            currency: coin,
            network: network
        }),
        error: function(err) {
            console.log(err);
        }
    });
};

export function migrateStake(session, amount) {
    if (!session || !amount) return Promise.reject("missing_parameters");

    return session.transact({
        actions: [{
            account: "strxstake",
            name: "stake.remove",
            data: {
                user: session.auth.actor,
                quantity: amount.toFixed(4) + " STRX"
            },
            authorization: [session.auth]
        },{
            account: "storex",
            name: "transfer",
            data: {
                from: session.auth.actor,
                to: "storexstake",
                quantity: amount.toFixed(4) + " STRX",
                memo: "add stake"
            },
            authorization: [session.auth]
        }]
    }, {
        broadcast: true
    });
};

export function addStake(state, stakeAmount, lockPeriod) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (stakeAmount == null) return Promise.reject("stakeAmount: Undefined");

        lockPeriod = parseInt(lockPeriod);

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: [{
                account: "storex",
                name: "transfer",
                data: {
                    from: state.session.auth.actor,
                    to: "storexstake",
                    quantity: stakeAmount.toFixed(4) + " STRX",
                    memo: (lockPeriod) ? "add stake " + lockPeriod : "add stake"
                },
                authorization: [state.session.auth]
            }]
        }, {
            blocksBehind: 3,
            expireSeconds: 30
        });
    } else {
        if (!state) return Promise.reject("missing_parameters");
        if (stakeAmount == null) return Promise.reject("stakeAmount: Undefined");

        lockPeriod = parseInt(lockPeriod);

        return state.session.transact({
            actions: [{
                account: "storex",
                name: "transfer",
                data: {
                    from: state.session.auth.actor,
                    to: "storexstake",
                    quantity: stakeAmount.toFixed(4) + " STRX",
                    memo: (lockPeriod) ? "add stake " + lockPeriod : "add stake"
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true
        });
    };
};

export function unStake(state, ids) {
    var actions = [];

    ids.forEach(id => {
        actions.push({
            account: "storexstake",
            name: "unstake",
            data: {
                user: state.session.auth.actor,
                id: id
            },
            authorization: [state.session.auth]
        });
    });

    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (ids == null) return Promise.reject("ids: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: actions
        }, {
            blocksBehind: 3,
            expireSeconds: 30
        });
    } else {
        if (!state) return Promise.reject("missing_parameters");
        if (ids == null) return Promise.reject("ids: Undefined");

        return state.session.transact({
            actions: actions
        }, {
            broadcast: true
        });
    };
};

export function reStake(state, ids) {
    var actions = [];

    ids.forEach(id => {
        actions.push({
            account: "storexstake",
            name: "restake",
            data: {
                user: state.session.auth.actor,
                id: id
            },
            authorization: [state.session.auth]
        });
    });

    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (ids == null) return Promise.reject("ids: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: actions
        }, {
            blocksBehind: 3,
            expireSeconds: 30
        });
    } else {
        if (!state) return Promise.reject("missing_parameters");
        if (ids == null) return Promise.reject("ids: Undefined");

        return state.session.transact({
            actions: actions
        }, {
            broadcast: true
        });
    };
};

export function withdrawStake(state, ids) {
    var actions = [];

    ids.forEach(id => {
        actions.push({
            account: "storexstake",
            name: "withdraw",
            data: {
                user: state.session.auth.actor,
                id: id
            },
            authorization: [state.session.auth]
        });
    });

    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (ids == null) return Promise.reject("ids: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: actions
        }, {
            blocksBehind: 3,
            expireSeconds: 30
        });
    } else {
        if (!state) return Promise.reject("missing_parameters");
        if (ids == null) return Promise.reject("ids: Undefined");

        return state.session.transact({
            actions: actions
        }, {
            broadcast: true
        });
    };
};

export function claimRewards(state) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: [{
                account: "storexstake",
                name: "claim",
                data: {
                    user: state.session.auth.actor
                },
                authorization: [state.session.auth]
            }]
        }, {
            blocksBehind: 3,
            expireSeconds: 30
        });
    } else {
        if (!state) return Promise.reject("missing_parameters");

        return state.session.transact({
            actions: [{
                account: "storexstake",
                name: "claim",
                data: {
                    user: state.session.auth.actor
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true
        });
    };
};

export function enrolDiscount(state, status) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (status == null) return Promise.reject("status: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: [{
                account: "storexstake",
                name: "fee.enrol",
                data: {
                    user: state.session.auth.actor,
                    fee_discount_enrol: status
                },
                authorization: [state.session.auth]
            }]
        }, {
            blocksBehind: 3,
            expireSeconds: 30
        });
    } else {
        if (!state) return Promise.reject("missing_parameters");
        if (status == null) return Promise.reject("status: Undefined");

        return state.session.transact({
            actions: [{
                account: "storexstake",
                name: "fee.enrol",
                data: {
                    user: state.session.auth.actor,
                    fee_discount_enrol: status
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true
        });
    };
};

export function mergeStakes(state, stakeIds) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (stakeIds?.length < 1) return Promise.reject("stakeIds: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey]) });

        return api.transact({
            actions: [{
                account: "storexstake",
                name: "merge",
                data: {
                    user: state.session.auth.actor,
                    ids: stakeIds
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 30
        });
    } else {
        if (!state) return Promise.reject("missing_parameters");
        if (stakeIds?.length < 1) return Promise.reject("stakeIds: Undefined");

        return state.session.transact({
            actions: [{
                account: "storexstake",
                name: "merge",
                data: {
                    user: state.session.auth.actor,
                    ids: stakeIds
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true
        });
    };
};

export function claimFaucet(state, programId) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (programId == null) return Promise.reject("programId: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: [{
                account: "token.faucet",
                name: "claim",
                data: {
                    programId: programId,
                    account: state.session.auth.actor 
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 30,
        });
    } else {
        if (!state || programId == null) return Promise.reject("missing_parameters");

        return state.session.transact({
            actions: [{
                account: "token.faucet",
                name: "claim",
                data: {
                    programId: 0,
                    account: state.session.auth.actor 
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true
        });
    };
};

export async function fetchTx(txId) {
    const result = await rpc.history_get_transaction(txId);

    return result;
};

export async function fetchV1Stake(session) {
    const result = await rpc.get_table_rows({
        json: true,
        code: "strxstake",
        scope: " " + session.auth.actor,
        table: "stakes"
    });

    return result;
};

export async function fetchV2StakeConf() {
    const result = await rpc.get_table_rows({
        json: true,
        code: "storexstake",
        scope: "storexstake",
        table: "config"
    });

    return result;
};

export async function fetchV2Stakes(session) {
    const result = await rpc.get_table_rows({
        json: true,
        code: "storexstake",
        scope: " " + session.auth.actor,
        table: "stakes",
        limit: 9999
    });

    return result;
};

export async function fetchV2Unstakes(session) {
    const result = await rpc.get_table_rows({
        json: true,
        code: "storexstake",
        scope: " " + session.auth.actor,
        table: "unstakes",
        limit: 9999
    });

    return result;
};

export async function fetchPrices() {
    const result = await rpc.get_table_rows({
        json: true,
        code: "strxoracle",
        scope: "strxoracle",
        table: "prices",
        limit: 9999
    });

    return result;
};

export async function fetchPools() {
    const result = await rpc.get_table_rows({
        json: true,
        code: "proton.swaps",
        scope: "proton.swaps",
        table: "pools",
        limit: -1
    });

    return result;
};

export async function fetchAlcorPool(poolId) {
    const result = await rpc.get_table_rows({
        json: true,
        code: "swap.alcor",
        scope: "swap.alcor",
        table: "pools",
        lower_bound: poolId,
        upper_bound: poolId,
        limit: -1
    });

    return result.rows?.[0];
};

export async function fetchAlcorPoolPositions(poolId) {
    const result = await rpc.get_table_rows({
        json: true,
        code: "swap.alcor",
        scope: poolId,
        table: "positions",
        limit: -1
    });

    return result.rows;
};

export async function fetchAlcorPositionStakes(incentiveId) {
    const result = await rpc.get_table_rows({
        json: true,
        code: "swap.alcor",
        scope: incentiveId,
        table: "stakes",
        limit: -1
    });

    return result.rows;
};

export async function fetchAlcorFarm(incentiveId) {
    const result = await rpc.get_table_rows({
        json: true,
        code: "swap.alcor",
        scope: "swap.alcor",
        table: "incentives",
        lower_bound: incentiveId,
        upper_bound: incentiveId,
        limit: -1
    });

    return result.rows?.[0];
};

export async function fetchDepositAddresses(session) {
    const result = await rpc.get_table_rows({
        json: true,
        code: "proton.wrap",
        scope: "proton.wrap",
        table: "addresses2",
        index_position: 2,
        key_type: "name",
        lower_bound: session.auth.actor,
        upper_bound: session.auth.actor,
        limit: 9999
    });

    return result;
};

export function collectFarmRewards(state, incentiveId, posId) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (!incentiveId) return Promise.reject("incentiveId: Undefined");
        if (!posId) return Promise.reject("posId: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: [{
                account: "swap.alcor",
                name: "getreward",
                data: {
                    incentiveId: incentiveId,
                    posId: posId
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 30,
        });
    } else {
        if (!state) return Promise.reject("state: Undefined");
        if (!incentiveId) return Promise.reject("incentiveId: Undefined");
        if (!posId) return Promise.reject("posId: Undefined");

        return state.session.transact({
            actions: [{
                account: "swap.alcor",
                name: "getreward",
                data: {
                    incentiveId: incentiveId,
                    posId: posId
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true
        });
    };
};

export function yieldFarm(state, poolId, inputCurrency, inputQuantity, outputCurrency, outputQuantity, infinityPosition, stakedPosition) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (!poolId) return Promise.reject("poolId: Undefined");
        if (!inputCurrency) return Promise.reject("inputCurrency: Undefined");
        if (!inputQuantity) return Promise.reject("inputQuantity: Undefined");
        if (!outputCurrency) return Promise.reject("outputCurrency: Undefined");
        if (!outputQuantity) return Promise.reject("outputQuantity: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        var actionArray = [{
            account: inputCurrency.contract,
            name: "transfer",
            data: {
                from: state.session.auth.actor,
                to: "swap.alcor",
                quantity: parseFloat(inputQuantity).toFixed(inputCurrency.precision) + " " + inputCurrency.ticker,
                memo: "deposit"
            },
            authorization: [state.session.auth]
        },{
            account: outputCurrency.contract,
            name: "transfer",
            data: {
                from: state.session.auth.actor,
                to: "swap.alcor",
                quantity: parseFloat(outputQuantity).toFixed(outputCurrency.precision) + " " + outputCurrency.ticker,
                memo: "deposit"
            },
            authorization: [state.session.auth]
        },{
            account: "swap.alcor",
            name: "addliquid",
            data: {
                poolId: poolId,
                owner: state.session.auth.actor,
                tokenADesired: parseFloat(inputQuantity).toFixed(inputCurrency.precision) + " " + inputCurrency.ticker,
                tokenBDesired: parseFloat(outputQuantity).toFixed(outputCurrency.precision) + " " + outputCurrency.ticker,
                tickLower: -443600,
                tickUpper: 443600,
                tokenAMin: parseFloat(inputQuantity * 0.995).toFixed(inputCurrency.precision) + " " + inputCurrency.ticker,
                tokenBMin: parseFloat(outputQuantity * 0.995).toFixed(outputCurrency.precision) + " " + outputCurrency.ticker,
                deadline: 0
            },
            authorization: [state.session.auth]
        }];

        if (isObjectEmpty(infinityPosition)) {
            actionArray.push({
                account: "swap.alcor",
                name: "stakelastpos",
                data: {
                    incentiveId: inputCurrency.farm
                },
                authorization: [state.session.auth]
            });
        } else if (isObjectEmpty(stakedPosition)) {
            actionArray.push({
                account: "swap.alcor",
                name: "stake",
                data: {
                    incentiveId: inputCurrency.farm,
                    posId: infinityPosition.id
                },
                authorization: [state.session.auth]
            });
        };

        return api.transact({
            actions: actionArray
        }, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 30,
        });
    } else {
        if (!state) return Promise.reject("state: Undefined");
        if (!poolId) return Promise.reject("poolId: Undefined");
        if (!inputCurrency) return Promise.reject("inputCurrency: Undefined");
        if (!inputQuantity) return Promise.reject("inputQuantity: Undefined");
        if (!outputCurrency) return Promise.reject("outputCurrency: Undefined");
        if (!outputQuantity) return Promise.reject("outputQuantity: Undefined");

        var actionArray = [{
            account: inputCurrency.contract,
            name: "transfer",
            data: {
                from: state.session.auth.actor,
                to: "swap.alcor",
                quantity: parseFloat(inputQuantity).toFixed(inputCurrency.precision) + " " + inputCurrency.ticker,
                memo: "deposit"
            },
            authorization: [state.session.auth]
        },{
            account: outputCurrency.contract,
            name: "transfer",
            data: {
                from: state.session.auth.actor,
                to: "swap.alcor",
                quantity: parseFloat(outputQuantity).toFixed(outputCurrency.precision) + " " + outputCurrency.ticker,
                memo: "deposit"
            },
            authorization: [state.session.auth]
        },{
            account: "swap.alcor",
            name: "addliquid",
            data: {
                poolId: poolId,
                owner: state.session.auth.actor,
                tokenADesired: parseFloat(inputQuantity).toFixed(inputCurrency.precision) + " " + inputCurrency.ticker,
                tokenBDesired: parseFloat(outputQuantity).toFixed(outputCurrency.precision) + " " + outputCurrency.ticker,
                tickLower: -443600,
                tickUpper: 443600,
                tokenAMin: parseFloat(inputQuantity * 0.995).toFixed(inputCurrency.precision) + " " + inputCurrency.ticker,
                tokenBMin: parseFloat(outputQuantity * 0.995).toFixed(outputCurrency.precision) + " " + outputCurrency.ticker,
                deadline: 0
            },
            authorization: [state.session.auth]
        }];

        if (isObjectEmpty(infinityPosition)) {
            actionArray.push({
                account: "swap.alcor",
                name: "stakelastpos",
                data: {
                    incentiveId: inputCurrency.farm
                },
                authorization: [state.session.auth]
            });
        } else if (isObjectEmpty(stakedPosition)) {
            actionArray.push({
                account: "swap.alcor",
                name: "stake",
                data: {
                    incentiveId: inputCurrency.farm,
                    posId: infinityPosition.id
                },
                authorization: [state.session.auth]
            });
        };

        return state.session.transact({
            actions: actionArray
        }, {
            broadcast: true
        });
    };
};

export function removeYieldFarm(state, poolId, liquidity, tokenA, tokenB) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (!poolId) return Promise.reject("poolId: Undefined");
        if (!liquidity) return Promise.reject("liquidity: Undefined");
        if (!tokenA) return Promise.reject("tokenA: Undefined");
        if (!tokenB) return Promise.reject("tokenB: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: [{
                account: "swap.alcor",
                name: "subliquid",
                data: {
                    poolId: poolId,
                    owner: state.session.auth.actor,
                    liquidity: liquidity,
                    tickLower: -443600,
                    tickUpper: 443600,
                    tokenAMin: parseFloat(0).toFixed(tokenA.precision) + " " + tokenA.ticker,
                    tokenBMin: parseFloat(0).toFixed(tokenB.precision) + " " + tokenB.ticker,
                    deadline: 0
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 30,
        });
    } else {
        if (!state) return Promise.reject("state: Undefined");
        if (!poolId) return Promise.reject("poolId: Undefined");
        if (!liquidity) return Promise.reject("liquidity: Undefined");
        if (!tokenA) return Promise.reject("tokenA: Undefined");
        if (!tokenB) return Promise.reject("tokenB: Undefined");

        return state.session.transact({
            actions: [{
                account: "swap.alcor",
                name: "subliquid",
                data: {
                    poolId: poolId,
                    owner: state.session.auth.actor,
                    liquidity: liquidity,
                    tickLower: -443600,
                    tickUpper: 443600,
                    tokenAMin: parseFloat(0).toFixed(tokenA.precision) + " " + tokenA.ticker,
                    tokenBMin: parseFloat(0).toFixed(tokenB.precision) + " " + tokenB.ticker,
                    deadline: 0
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true
        });
    };
};

export function createListing(state, inputs) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (!inputs) return Promise.reject("inputs: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        console.log(inputs);

        return api.transact({
            actions: [{
                account: "storexcore",
                name: "list.create",
                data: {
                    seller: state.session.auth.actor,
                    title: inputs.title,
                    options: inputs.options,
                    variations: inputs.variations,
                    shipping: inputs.shipping,
                    payments: inputs.payments,
                    escrow: inputs.escrow
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 30,
        });
    } else {
        if (!state || !inputs) return Promise.reject("missing_parameters");

        console.log(inputs);

        return state.session.transact({
            actions: [{
                account: "storexcore",
                name: "list.create",
                data: {
                    seller: state.session.auth.actor,
                    title: inputs.title,
                    options: inputs.options,
                    variations: inputs.variations,
                    shipping: inputs.shipping,
                    payments: inputs.payments,
                    escrow: inputs.escrow
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true
        });
    };
};

export function endListing(state, listingId) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (!listingId) return Promise.reject("listingId: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: [{
                account: "storexcore",
                name: "list.end",
                data: {
                    listing_id: listingId
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 30,
        });
    } else {
        if (!state) return Promise.reject("state: Undefined");
        if (!listingId) return Promise.reject("listingId: Undefined");

        return state.session.transact({
            actions: [{
                account: "storexcore",
                name: "list.end",
                data: {
                    listing_id: listingId
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true
        });
    };
};

export function checkoutListings(state, listings) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (!listings) return Promise.reject("listings: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        console.log(listings);

        var actionArr = [];

        listings.forEach(listing => {
            for(let x = 0; x < listing.quantity; x++) {
                actionArr.push({
                    account: "storexcore",
                    name: "list.order",
                    data: {
                        buyer: state.session.auth.actor,
                        listing_id: listing.id,
                        variation_id: listing.variationId,
                        shipping_country_code: listing.shippingCountryCode,
                        payment: {"sym": listing.payment.sym, "contract": listing.payment.contract}
                    },
                    authorization: [state.session.auth]
                });
            };
        });

        return api.transact({
            actions: actionArr
        }, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 30,
        });
    } else {
        if (!state || !listings) return Promise.reject("missing_parameters");

        console.log(listings);

        var actionArr = [];

        listings.forEach(listing => {
            for(let x = 0; x < listing.quantity; x++) {
                actionArr.push({
                    account: "storexcore",
                    name: "list.order",
                    data: {
                        buyer: state.session.auth.actor,
                        listing_id: listing.id,
                        variation_id: listing.variationId,
                        shipping_country_code: listing.shippingCountryCode,
                        payment: {"sym": listing.payment.sym, "contract": listing.payment.contract}
                    },
                    authorization: [state.session.auth]
                });
            };
        });

        return state.session.transact({
            actions: actionArr
        }, {
            broadcast: true
        });
    };
};

export function purchaseListings(state, actions) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (!actions) return Promise.reject("listings: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: actions
        }, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 30,
        });
    } else {
        if (!state || !actions) return Promise.reject("missing_parameters");

        return state.session.transact({
            actions: actions
        }, {
            broadcast: true
        });
    };
};

export function completeOrder(state, orderId) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (!orderId) return Promise.reject("orderId: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: [{
                account: "storexcore",
                name: "list.complete",
                data: {
                    buyer: state.session.auth.actor,
                    order_id: orderId
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 30,
        });
    } else {
        if (!state) return Promise.reject("state: Undefined");
        if (!orderId) return Promise.reject("orderId: Undefined");

        return state.session.transact({
            actions: [{
                account: "storexcore",
                name: "list.complete",
                data: {
                    buyer: state.session.auth.actor,
                    order_id: orderId
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true
        });
    }
};

export function refundOrder(state, orderId, refund) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (!orderId) return Promise.reject("orderId: Undefined");
        if (!refund) return Promise.reject("refund: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: [{
                account: "storexcore",
                name: "list.refund",
                data: {
                    seller: state.session.auth.actor,
                    order_id: orderId,
                    refund: refund
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 30,
        });
    } else {
        if (!state || !orderId || !refund) return Promise.reject("missing_parameters");

        return state.session.transact({
            actions: [{
                account: "storexcore",
                name: "list.refund",
                data: {
                    seller: state.session.auth.actor,
                    order_id: orderId,
                    refund: refund
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true
        });
    };
};

export function refundOrderManual(state, orderId, contract, seller, buyer, refund) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (!orderId) return Promise.reject("orderId: Undefined");
        if (!contract) return Promise.reject("contract: Undefined");
        if (!seller) return Promise.reject("seller: Undefined");
        if (!buyer) return Promise.reject("buyer: Undefined");
        if (!refund) return Promise.reject("refund: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: [{
                account: contract,
                name: "transfer",
                data: {
                    from: seller,
                    to: buyer,
                    quantity: refund,
                    memo: "refund #" + orderId
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 30,
        });
    } else {
        if (!state || !orderId || !contract || !seller || !buyer || !refund) return Promise.reject("missing_parameters");

        return state.session.transact({
            actions: [{
                account: contract,
                name: "transfer",
                data: {
                    from: seller,
                    to: buyer,
                    quantity: refund,
                    memo: "refund #" + orderId
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true
        });
    };
};

export function claimFunds(state, orderId) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (!orderId) return Promise.reject("orderId: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: [{
                account: "storexcore",
                name: "list.claim",
                data: {
                    seller: state.session.auth.actor,
                    order_id: orderId
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 30,
        });
    } else {
        if (!state || !orderId) return Promise.reject("missing_parameters");

        return state.session.transact({
            actions: [{
                account: "storexcore",
                name: "list.claim",
                data: {
                    seller: state.session.auth.actor,
                    order_id: orderId
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true
        });
    };
};

export function extendEscrow(state, orderId) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (!orderId) return Promise.reject("orderId: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: [{
                account: "storexcore",
                name: "list.extend",
                data: {
                    buyer: state.session.auth.actor,
                    order_id: orderId
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 30,
        });
    } else {
        if (!state || !orderId) return Promise.reject("missing_parameters");

        return state.session.transact({
            actions: [{
                account: "storexcore",
                name: "list.extend",
                data: {
                    buyer: state.session.auth.actor,
                    order_id: orderId
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true
        });
    };
};

export function startDispute(state, orderId, isSeller, disputeAmount, reason) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (!orderId) return Promise.reject("orderId: Undefined");
        if (isSeller == null) return Promise.reject("isSeller: Undefined");
        if (!disputeAmount) return Promise.reject("disputeAmount: Undefined");
        if (!reason) return Promise.reject("reason: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: [{
                account: "arb.strx",
                name: "disp.start",
                data: {
                    user: state.session.auth.actor,
                    order_id: orderId,
                    is_seller: isSeller,
                    dispute_amount: disputeAmount,
                    reason: reason
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: false,
            blocksBehind: 3,
            expireSeconds: 3600,
        });
    } else {
        if (!state) return Promise.reject("state: Undefined");
        if (!orderId) return Promise.reject("orderId: Undefined");
        if (isSeller == null) return Promise.reject("isSeller: Undefined");
        if (!disputeAmount) return Promise.reject("disputeAmount: Undefined");
        if (!reason) return Promise.reject("reason: Undefined");

        return state.session.transact({
            actions: [{
                account: "arb.strx",
                name: "disp.start",
                data: {
                    user: state.session.auth.actor,
                    order_id: orderId,
                    is_seller: isSeller,
                    dispute_amount: disputeAmount,
                    reason: reason
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: false,
            signed: true
        });
    }
};

export function cancelDispute(state, disputeId) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (!disputeId) return Promise.reject("disputeId: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: [{
                account: "arb.strx",
                name: "disp.cancel",
                data: {
                    dispute_id: disputeId
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: false,
            blocksBehind: 3,
            expireSeconds: 3600,
        });
    } else {
        if (!state) return Promise.reject("state: Undefined");
        if (!disputeId) return Promise.reject("disputeId: Undefined");

        return state.session.transact({
            actions: [{
                account: "arb.strx",
                name: "disp.cancel",
                data: {
                    dispute_id: disputeId
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: false,
            signed: true
        });
    }
};

export function voteDispute(state, disputeId, party) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (!disputeId) return Promise.reject("disputeId: Undefined");
        if (!party) return Promise.reject("party: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: [{
                account: "arb.strx",
                name: "votedispute",
                data: {
                    user: state.session.auth.actor,
                    dispute_id: disputeId,
                    proposer: party
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 3600,
        });
    } else {
        if (!state) return Promise.reject("state: Undefined");
        if (!disputeId) return Promise.reject("disputeId: Undefined");
        if (!party) return Promise.reject("party: Undefined");

        return state.session.transact({
            actions: [{
                account: "arb.strx",
                name: "votedispute",
                data: {
                    user: state.session.auth.actor,
                    dispute_id: disputeId,
                    proposer: party
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true
        });
    }
};

export function resolveDispute(state, disputeId) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (!disputeId) return Promise.reject("disputeId: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: [{
                account: "arb.strx",
                name: "disp.resolve",
                data: {
                    dispute_id: disputeId
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 3600,
        });
    } else {
        if (!state) return Promise.reject("state: Undefined");
        if (!disputeId) return Promise.reject("disputeId: Undefined");

        return state.session.transact({
            actions: [{
                account: "arb.strx",
                name: "disp.resolve",
                data: {
                    dispute_id: disputeId
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true
        });
    }
};

export function joinPanel(state) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: [{
                account: "storex",
                name: "transfer",
                data: {
                    from: state.session.auth.actor,
                    to: "arb.strx",
                    quantity: "100000.0000 STRX",
                    memo: "entrance fee"
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 3600,
        });
    } else {
        if (!state) return Promise.reject("state: Undefined");

        return state.session.transact({
            actions: [{
                account: "storex",
                name: "transfer",
                data: {
                    from: state.session.auth.actor,
                    to: "arb.strx",
                    quantity: "100000.0000 STRX",
                    memo: "entrance fee"
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true
        });
    }
};

export function leavePanel(state) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: [{
                account: "arb.strx",
                name: "leave",
                data: {
                    user: state.session.auth.actor
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 3600,
        });
    } else {
        if (!state) return Promise.reject("state: Undefined");

        return state.session.transact({
            actions: [{
                account: "arb.strx",
                name: "leave",
                data: {
                    user: state.session.auth.actor
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true
        });
    }
};

export function redeemVoucher(state, voucherCode) {
    if (state.w3aConnected) {
        if (!state.w3aPrivateKey) return Promise.reject("state.w3aPrivateKey: Undefined");
        if (!voucherCode) return Promise.reject("voucherCode: Undefined");

        const api = new Api({ rpc, signatureProvider: new JsSignatureProvider([state.w3aPrivateKey])});

        return api.transact({
            actions: [{
                account: "voucher.strx",
                name: "claim",
                data: {
                    user: state.session.auth.actor,
                    code: voucherCode
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 3600,
        });
    } else {
        if (!state) return Promise.reject("state: Undefined");
        if (!voucherCode) return Promise.reject("voucherCode: Undefined");

        return state.session.transact({
            actions: [{
                account: "voucher.strx",
                name: "claim",
                data: {
                    user: state.session.auth.actor,
                    code: voucherCode
                },
                authorization: [state.session.auth]
            }]
        }, {
            broadcast: true
        });
    }
};

export async function fetchBalance(username, currency) {
    try {
        return rpc.get_currency_balance(
            currency.contract,
            username,
            currency.ticker
        );
    } catch (error) {
        console.log("RPC Erorr:", error);
    };
};

export async function fetchOracles() {
    const result = await rpc.get_table_rows({
        json: true,
        code: "oracles",
        scope: "oracles",
        table: "data",
        limit: 9999
    });

    return result;
};

export async function fetchStorexOracles() {
    const result = await rpc.get_table_rows({
        json: true,
        code: "strxoracle",
        scope: "strxoracle",
        table: "prices",
        limit: 9999
    });

    return result;
};

export async function fetchArbitratorStatus(user) {
    const result = await rpc.get_table_rows({
        json: true,
        code: "arb.strx",
        scope: "arb.strx",
        table: "arbitrators",
        upper_bound: user,
        lower_bound: user,
        limit: 9999
    });

    return result.rows?.[0];
};

export async function fetchDispute(disputeId) {
    const result = await rpc.get_table_rows({
        json: true,
        code: "arb.strx",
        scope: "arb.strx",
        table: "disputes",
        upper_bound: disputeId,
        lower_bound: disputeId
    });

    return result.rows?.[0];
};